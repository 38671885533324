import { gql } from "@apollo/client";

export const IS_ACTIVE_POLICY = gql`
  query IsActivePolicy($input: PolicyInput!) {
    policy(input: $input) {
      action
      mrn
    }
  }
`;
