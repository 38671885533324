import { gql } from "@apollo/client";

export const ASSET_REPORT_QUERIES_FIELDS = gql`
  fragment AssetReportQueriesFields on AssetReportQueryConnection {
    edges {
      node {
        assessment {
          state
          rendered
          results {
            actual
            expected
            error
          }
          needsUpgrade
          extendedResults {
            actual {
              name
              title
              fields {
                name
                description
                path
                type
                value
                updatedAt
                numElements
                nestedContent
              }
              type
              path
              numElements
              nestedContent
              assessmentField {
                name
                description
                path
                type
                value
                updatedAt
                numElements
                nestedContent
              }
            }
            numResources
            resourceName
            resourceTitle
          }
        }
        action
        data
        id
        mquery {
          uid
          mrn
          title
          docs {
            desc
            remediations {
              ... on Remediations {
                entries {
                  id
                  desc
                }
              }
              ... on AuthenticationRequired {
                message
                code
              }
            }
            references {
              title
              url
            }
            audit
          }
          impact {
            value
            rating
          }
          mql
          tags {
            key
            value
          }
          properties {
            uid
            mrn
            title
            mql
            setByPolicyMrn
          }
          parent
          variants {
            id
            title
            icon
          }
          variantQueries {
            uid
            mrn
            title
            mql
            filter
            filterID
          }
          action
        }
        mqueryState
        mqueryType
        policies
        score {
          value
          type
          completion
          weight
          message
          grade
        }
        exception {
          id
          action
          justification
          reviewStatus
        }
      }
      cursor
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
`;
