import { gql } from "@apollo/client";

export const CREATE_CLIENT_INTEGRATION = gql`
  mutation CreateClientIntegration($input: CreateClientIntegrationInput!) {
    createClientIntegration(input: $input) {
      integration {
        mrn
        token
        name
        status
        created
        lastCheckin
        type
        messages {
          message
          status
        }
        configurationOptions {
          ... on K8sConfigurationOptions {
            scanNodes
            scanNodesStyle
            scanWorkloads
            scanDeploys
            certificateManager
            scanPublicImages
            namespaceAllowList
            namespaceDenyList
          }
          ... on AWSConfigurationOptions {
            accountIds
            region
            isOrganization
            scanConfiguration {
              accountScan
              ec2Scan
              ecrScan
              ecsScan
              cronScaninHours
              eventScanTriggers {
                scanType
                eventSource
                eventDetailType
              }
              ec2ScanOptions {
                ssm
                instanceConnect
                awsSecretsManagerVault
                secretsMetadataQuery
                instanceIdsFilter
                regionsFilter
                tagsFilter
                vaultType
                ebsVolumeScan
                ebsScanOptions {
                  targetInstancesPerScanner
                  maxAsgInstances
                }
              }
              vpcConfiguration {
                CIDR
                useDefaultVPC
                useMondooVPC
              }
            }
            snsEndpoint
            originAWSAccountId
            cloudFormationTemplateUrl
          }
          ... on HostedAwsConfigurationOptions {
            accessKeyId
            role
          }
          ... on AgentOptions {
            id
            version
            notes
            platform {
              name
              release
              arch
              kind
              runtime
            }
            hostname
            ipaddress
          }
          ... on AzureConfigurationOptions {
            tenantId
            clientId
            subscriptionsWhitelist
            subscriptionsBlacklist
            scanVms
          }
          ... on Ms365ConfigurationOptions {
            tenantId
            clientId
          }
          ... on GcpConfigurationOptions {
            projectId
            organizationId
            discoverAll
          }
          ... on OktaConfigurationOptions {
            organization
          }
          ... on GcsBucketConfigurationOptions {
            output
            bucket
          }
          ... on GitlabConfigurationOptions {
            group
            type
            discoverGroups
            discoverProjects
            discoverTerraform
            discoverK8sManifests
            baseUrl
          }
          ... on GithubConfigurationOptions {
            owner
            repository
            githubOrg: organization
            githubType: type
            reposAllowList
            reposDenyList
            githubDiscoverK8sManifests: discoverK8sManifests
            githubDiscoverTerraform: discoverTerraform
            enterpriseUrl
          }
          ... on GoogleWorkspaceConfigurationOptions {
            customerId
            impersonatedUserEmail
          }
          ... on SlackConfigurationOptions {
            placeholder
          }
          ... on PostgresConfigurationOptions {
            host
            port
            database
            user
          }
          ... on OciConfigurationOptions {
            tenancyOcid
            userOcid
            region
            fingerprint
          }
        }
      }
    }
  }
`;
