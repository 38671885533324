import { gql } from "@apollo/client";

export const LOAD_POLICY_DASHBOARD_STATS = gql`
  query LoadPolicyDashboardStats($input: PolicyDashboardStatsInput!) {
    policyDashboardStats(input: $input) {
      averagePolicyScores {
        A
        B
        C
        D
        F
        X
        U
      }
      distribution {
        count
        category
      }
      checkStatsBySeverity {
        passedAcrossAllAssets
        total
        severity
      }
      assetStats {
        A
        B
        C
        D
        F
        total
        Error
        U
      }
      bestPolicies {
        mrn
        name
        score
      }
      worstPolicies {
        mrn
        name
        score
      }
      topPolicyActions {
        name
        severity
        score {
          total
        }
      }
    }
  }
`;
