import { gql } from "@apollo/client";

export const GET_ASSET_POLICY_DISTRIBUTION = gql`
  query GetAssetPolicyDistribution($input: AssetReportInput!) {
    assetReport(input: $input) {
      ... on AssetReport {
        policyDistribution {
          category
          count
        }
      }
    }
  }
`;
