import { gql } from "@apollo/client";

export const POLICY_QUERY_SUMMARIES = gql`
  query PolicyQuerySummaries($input: SpacePolicySummaryInput!) {
    spacePolicySummary(input: $input) {
      spaceMrn
      policyMrn
      querySummaries {
        totalCount
        edges {
          cursor
          node {
            id
            assetGrades {
              ...AssetGradeDistributionFields
            }
            mquery {
              action
              mrn
              title
              code
              codeId
              severity
              impact {
                rating
                value
              }
            }
            mqueryState
            mqueryType
            exception {
              id
              action
              justification
              reviewStatus
            }
          }
        }
      }
    }
  }
`;
