import { gql } from "@apollo/client";

export const GET_ASSET_REPORT_DATA_QUERIES = gql`
  query GetAssetReportDataQueries(
    $input: AssetReportInput!
    $first: Int
    $after: String
    $packsFilter: [String]
  ) {
    assetReport(input: $input) {
      ... on AssetReport {
        dataQueries(first: $first, after: $after, packsFilter: $packsFilter) {
          totalCount
          edges {
            cursor
            node {
              id
              mquery {
                uid
                mrn
                title
                docs {
                  desc
                  remediations {
                    ... on Remediations {
                      entries {
                        id
                        desc
                      }
                    }
                    ... on AuthenticationRequired {
                      message
                      code
                    }
                  }
                  references {
                    title
                    url
                  }
                  audit
                  remediation
                }
                impact {
                  value
                  rating
                }
                mql
                tags {
                  key
                  value
                }
                properties {
                  uid
                  mrn
                  title
                  mql
                  setByPolicyMrn
                }
                parent
                variants {
                  id
                  title
                  icon
                }
                variantQueries {
                  uid
                  mrn
                  title
                  mql
                  filter
                  filterID
                }
                action
              }
              score {
                id
                value
                type
                completion
                weight
                message
                grade
              }
              mqueryState
              mqueryType
              data
              assessment {
                state
                rendered
                results {
                  actual
                  expected
                  error
                }
                extendedResults {
                  resourceName
                  resourceTitle
                  numResources
                  actual {
                    name
                    title
                    fields {
                      name
                      description
                      path
                      type
                      value
                      updatedAt
                      numElements
                      nestedContent
                    }
                    type
                    path
                    numElements
                    nestedContent
                    assessmentField {
                      name
                      description
                      path
                      type
                      value
                      updatedAt
                      numElements
                      nestedContent
                    }
                  }
                }
                needsUpgrade
              }
              action
              policies
              policyId
              policyName
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    }
  }
`;
