import { gql } from "@apollo/client";

export const ASSET_REPORT_LIST_POLICIES_FIELDS = gql`
  fragment AssetReportListPoliciesFields on AssetReportPolicyListConnection {
    totalCount
    edges {
      cursor
      node {
        mrn
        name
        version
        category
        action
        scoreStats {
          total
          passed
          failed
          incomplete
          errors
          skipped
          worst
          disabled
          snoozed
          unknown
        }
        score {
          id
          value
          type
          completion
          weight
          message
          grade
        }
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
`;
