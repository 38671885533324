import { gql } from "@apollo/client";

export const GET_ASSET_REPORT_CHECK_STATS = gql`
  query GetAssetReportCheckStats($input: AssetReportInput!) {
    assetReport(input: $input) {
      ... on AssetReport {
        checks {
          stats {
            critical {
              passed
              failed
              errored
            }
            high {
              passed
              failed
              errored
            }
            medium {
              passed
              failed
              errored
            }
            low {
              passed
              failed
              errored
            }
            unscored
          }
        }
      }
    }
  }
`;
