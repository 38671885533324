import { gql } from "@apollo/client";
import { ASSET_REPORT_LIST_POLICIES_FIELDS } from "../fragments/AssetReportListPoliciesFields";

export const LOAD_ASSET_LIST_POLICIES = gql`
  ${ASSET_REPORT_LIST_POLICIES_FIELDS}
  query LoadAssetListPolicies(
    $input: AssetReportInput!
    $first: Int
    $after: String
    $orderBy: AssetReportPolicyOrder
    $filter: AssetReportListPoliciesFilter
  ) {
    assetReport(input: $input) {
      ... on AssetReport {
        listPolicies(
          first: $first
          after: $after
          orderBy: $orderBy
          filter: $filter
        ) {
          ...AssetReportListPoliciesFields
        }
      }
    }
  }
`;
