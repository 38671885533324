import { gql } from "@apollo/client";

export const LOAD_VULN_DASHBOARD_STATS = gql`
  query LoadVulnDashboardStats($input: VulnDashboardStatsInput!) {
    vulnDashboardStats(input: $input) {
      remediatedBySeverity {
        severity
        mttrInMinutes
        fixed
        total
      }
      stats {
        counts {
          severity
          count
        }
        statType
        total
      }
    }
  }
`;
