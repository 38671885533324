import { gql } from "@apollo/client";

export const CREATET_TICKET = gql`
  mutation CreateTicket($input: TicketInput!) {
    createTicket(input: $input) {
      id
      url
      integration
    }
  }
`;
