import { gql } from "@apollo/client";
import { ASSET_GRADE_DISTRIBUTION_FIELDS } from "../fragments/AssetGradeDistributionFields";
import { PLATFORM_FIELDS } from "../fragments/PlatformFields";

export const SPACE_POLICY_REPORT = gql`
  ${ASSET_GRADE_DISTRIBUTION_FIELDS}
  ${PLATFORM_FIELDS}
  query SpacePolicyReport($input: SpacePolicyReportInput!) {
    spacePolicyReport(input: $input) {
      ... on SpacePolicyReport {
        spaceMrn
        policyMrn
        assetGrades {
          ...AssetGradeDistributionFields
        }
        assetSummaries {
          edges {
            cursor
            node {
              id
              policyMrn
              mqueryResultsDistribution {
                pass
                fail
                error
                unknown
              }
              asset {
                id
                mrn
                state
                name
                updatedAt
                platform {
                  ...PlatformFields
                }
                score {
                  id
                  value
                  type
                  completion
                  weight
                  message
                }
                referenceIDs
              }
              score {
                id
                value
                type
                completion
                weight
                message
              }
              grade
            }
          }
        }
      }
    }
  }
`;
