import { gql } from "@apollo/client";
import { ASSET_REPORT_CHECKS_FIELDS } from "../fragments/AssetReportChecksFields";

export const LOAD_ASSET_REPORT_CHECKS = gql`
  query LoadAssetReportChecks(
    $input: AssetReportInput!
    $first: Int
    $after: String
    $policyFilter: [String]
    $orderBy: AssetReportQueryOrder
    $filter: String
    $impact: [String!]
    $policyMrns: [String!]
  ) {
    assetReport(input: $input) {
      ... on AssetReport {
        checks(
          first: $first
          after: $after
          policyFilter: $policyFilter
          orderBy: $orderBy
          filter: $filter
          impact: $impact
          policyMrns: $policyMrns
        ) {
          ...AssetReportChecksFields
        }
      }
    }
  }
  ${ASSET_REPORT_CHECKS_FIELDS}
`;
