import { gql } from "@apollo/client";

export const PLATFORM_FIELDS = gql`
  fragment PlatformFields on Platform {
    name
    release
    arch
    kind
    runtime
  }
`;
