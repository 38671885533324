import { gql } from "@apollo/client";
import { RISK_DISTRIBUTION } from "../fragments/RiskDistribution";

export const LOAD_ORGANIZATION_STATS = gql`
  query LoadOrganizationStats(
    $mrn: String!
    $first: Int
    $after: String
    $query: String
  ) {
    organization(mrn: $mrn) {
      id
      mrn
      name
      spacesCount
      spacesList(first: $first, after: $after, query: $query) {
        totalCount
        edges {
          cursor
          node {
            id
            mrn
            name
            stats {
              ...RiskDistribution
            }
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
  ${RISK_DISTRIBUTION}
`;
