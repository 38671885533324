import { gql } from "@apollo/client";

export const ASSET_REPORT_CHECKS_FIELDS = gql`
  fragment AssetReportChecksFields on AssetReportQueryConnection {
    totalCount
    edges {
      cursor
      node {
        id
        mquery {
          uid
          mrn
          title
          docs {
            desc
            audit
            remediations {
              ... on Remediations {
                entries {
                  desc
                  id
                }
              }
              ... on AuthenticationRequired {
                message
                code
              }
            }
            references {
              title
              url
            }
          }
          impact {
            value
            rating
          }
          mql
          tags {
            key
            value
          }
          properties {
            uid
            mrn
            title
            mql
            setByPolicyMrn
          }
          parent
          variants {
            id
            mrn
            title
            icon
          }
          variantQueries {
            uid
            mrn
            title
            mql
            filter
            filterID
          }
          action
        }
        score {
          id
          value
          type
          completion
          weight
          message
          grade
        }
        mqueryState
        mqueryType
        data
        assessment {
          state
          rendered
          results {
            actual
            expected
            error
          }
          extendedResults {
            resourceName
            resourceTitle
            numResources
            actual {
              name
              title
              fields {
                name
                description
                path
                type
                value
                updatedAt
                numElements
                nestedContent
              }
              type
              path
              numElements
              nestedContent
              assessmentField {
                name
                description
                path
                type
                value
                updatedAt
                numElements
                nestedContent
              }
            }
          }
          needsUpgrade
        }
        action
        policies
        policyId
        policyName
        exception {
          id
          reviewStatus
          action
          justification
        }
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
`;
