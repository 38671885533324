import { gql } from "@apollo/client";

export const UPDATE_ORGANIZATION_NAME = gql`
  mutation UpdateOrganizationName($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      organization {
        name
        mrn
      }
    }
  }
`;
