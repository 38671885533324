import { gql } from "@apollo/client";

export const GET_POLICY_PROPS = gql`
  query GetPolicyProps($input: PolicyInput!) {
    policy(input: $input) {
      mrn
      properties {
        uid
        mrn
        title
        mql
      }
    }
  }
`;
