import { gql } from "@apollo/client";
import { ASSET_GRADE_DISTRIBUTION_FIELDS } from "../fragments/AssetGradeDistributionFields";

export const SPACE_REPORT = gql`
  ${ASSET_GRADE_DISTRIBUTION_FIELDS}
  query SpaceReport($input: SpaceReportInput!) {
    spaceReport(input: $input) {
      ... on SpaceReport {
        spaceMrn
        policyReportSummaries {
          totalCount
          edges {
            cursor
            node {
              assetGrades {
                ...AssetGradeDistributionFields
              }
              policy {
                mrn
                name
                assigned
                action
                version
                isPublic
                createdAt
                updatedAt
                mqueryCounts {
                  total
                }
              }
            }
          }
        }
      }
    }
  }
`;
