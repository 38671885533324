import { gql } from "@apollo/client";
import { RISK_DISTRIBUTION } from "../fragments/RiskDistribution";

export const LOAD_VIEWER = gql`
  query LoadViewer {
    viewer {
      mrn
      email
      name
      state
      organizations {
        id
        mrn
        name
        capabilities
        spacesCount
        subscriptionInfo {
          basePlan {
            name
            id
          }
        }
        members {
          totalCount
        }
        owners: members(
          first: 1
          rolesFilter: "//iam.api.mondoo.app/roles/owner"
        ) {
          totalCount
          edges {
            cursor
            node {
              user {
                mrn
                name
                email
              }
            }
          }
        }
      }
      firstSpace {
        id
        mrn
        name
        organization {
          id
          mrn
          name
        }
        settings {
          eolAssetsConfiguration {
            enable
            monthsInAdvance
          }
          garbageCollectAssetsConfiguration {
            enable
            afterDays
          }
          terminatedAssetsConfiguration {
            cleanup
            after
          }
          unusedServiceAccountsConfiguration {
            cleanup
            after
          }
          platformVulnerabilityConfiguration {
            enable
          }
          casesConfiguration {
            autoCreate
          }
        }
        stats {
          ...RiskDistribution
        }
      }
    }
    viewerSettings {
      key
      value
    }
  }
  ${RISK_DISTRIBUTION}
`;
