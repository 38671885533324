import { gql } from "@apollo/client";

export const GET_SPACE_POLICY_COUNT = gql`
  query GetSpacePolicyCount($input: SpaceReportInput!) {
    spaceReport(input: $input) {
      ... on SpaceReport {
        policyReportSummaries {
          totalCount
        }
        spaceMrn
      }
    }
  }
`;
