import { gql } from "@apollo/client";

export const GET_END_OF_LIFE = gql`
  query GetEndOfLife($input: AssetReportInput!) {
    assetReport(input: $input) {
      ... on AssetReport {
        analysis {
          eol {
            date
            status
          }
        }
      }
    }
  }
`;
