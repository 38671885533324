import { gql } from "@apollo/client";

export const ASSET_GRADE_DISTRIBUTION_FIELDS = gql`
  fragment AssetGradeDistributionFields on AssetGradeDistribution {
    total
    A
    B
    C
    D
    F
    U
    X
  }
`;
